<script>
import Layout from "../../layouts/auth";
import {
  notificationMethods
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import Vue from "vue";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      identifier: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      login_gateway_url: process.env.VUE_APP_ROOT_API + 'v1/auth-gateway/steam'
    };
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  methods: {
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          const { email, password } = this;
          if (email && password) {
            this.login({ email, password });
          }

      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('register.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/logo.svg" alt height="34" />
                </span>
              </div>
            </div>
            <div class="text-center pb-2">
              <h3>{{$t('register.steam.prompt')}}</h3>
            </div>
            <div class="mt-2 text-center">
              <a :href="login_gateway_url">
                <img src="https://cdn.cftools.de/olymp/images/login/steam.png">
              </a>
              <p><small>{{$t('login.steam.warning')}}</small></p>
            </div>
          </div>
          <div class="pt-0 pb-4 text-center">
            <h5>
              <router-link tag="a" :to="{name: 'login'}">
                {{$t('register.login')}}
              </router-link>
            </h5>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-3 text-center">
          <small>
            © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
            <small style="font-size: 10px;">
              <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
              /
              <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
            </small>
          </small>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
